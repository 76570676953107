import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import {
  activateBoost,
  activateSuperBoost,
  cancelComboLoading,
  cancelDailyRewardLoading,
  cancelTapsLoading,
  cancelUserLoading,
  checkBuySuperBoosterStatus,
  claimDailyReward,
  getComboData,
  getDailyRewardInfo,
  getMainData,
  getUserBalanceWithItems,
  getUserStatus,
  getUserTaps,
  increaseUserBalance,
  recheckUserBalance,
  registerUser,
  saveUserWallet,
  sendUserTaps,
  setComboData,
  setDailyRewardInfo,
  setMainData,
  setUserBalanceWithItems,
  setUserBoosters,
  setUserStatus,
  setUserTaps,
} from '../store/features/userSlice';
import {
  ServiceModalName,
  storageTaps,
  superBoostHash,
  superBoostHashPrice,
  superBoostInfoModalStatus,
} from '../constants';
import { sendGTMEvent, sendGTMPurchaseEvent } from '../utils';
import { addServiceModal, removeServiceModal } from '../store/features/serviceModalSlice';
import { getLevelData, setLevelData } from '../store/features/levelSlice';
import { setUserLeaderboardData } from '../store/features/leaderboardSlice';

export function* saveUserWalletSaga({ payload }) {
  const user = yield select((state) => state.user.user);
  const token = yield select((state) => state.user.token);
  if (token && user) {
    try {
      yield call(fetch, `${process.env.REACT_APP_SERVICE_URL}/api/connect-wallet`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify({
          user_id: user.id,
          ton_address: payload,
        }),
      });
    } catch (e) {
      console.error(e);
    }
  }
}

export function* checkUserExistSaga() {
  const { token, country } = yield select((state) => state.user);
  if (token) {
    try {
      const response = yield call(fetch, `${process.env.REACT_APP_SERVICE_URL}/api/user-exists`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });
      const data = yield response.json();
      yield put(setUserStatus(data.exists));
    } catch (e) {
      console.error(e);
    }
  }
}

export function* registerUserSaga({ payload: refererId }) {
  const { user, token, country } = yield select((state) => state.user);
  if (token && user) {
    try {
      if (refererId) {
        sendGTMEvent('referral_user_registration', 'application', 'opening', 'Register user by referral link');
      } else {
        sendGTMEvent('user_registration', 'application', 'opening', 'Register user');
      }
      let fullname = user.firstName;
      if (user.lastName) {
        fullname = fullname + ' ' + user.lastName;
      }
      let userInfo = {
        user_id: user.id,
        fullname,
        username: user.username,
        is_premium: user?.isPremium,
        // country_code: country,
      };
      if (refererId) {
        userInfo.referer_id = +refererId;
      }

      yield call(fetch, `${process.env.REACT_APP_SERVICE_URL}/api/create-user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(userInfo),
      });

      yield put(setUserStatus(true));
      yield put(getUserTaps());
      yield put(getUserBalanceWithItems());
      yield put(getLevelData());
      yield put(getDailyRewardInfo());
    } catch (e) {
      console.error(e);
    }
  }
}

export function* getUserBalanceSaga() {
  const user = yield select((state) => state.user.user);
  const token = yield select((state) => state.user.token);
  if (token) {
    try {
      const response = yield call(fetch, `${process.env.REACT_APP_SERVICE_URL}/api/balance/${user.id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });

      const data = yield response.json();

      yield put(setUserBalanceWithItems(data));

    } catch (e) {
      console.error(e);
      yield put({ type: cancelUserLoading.type });
    }
  }
}

export function* getUserTapsSaga() {
  const token = yield select((state) => state.user.token);
  if (token) {
    try {
      const response = yield call(fetch, `${process.env.REACT_APP_SERVICE_URL}/api/available-taps`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });

      const data = yield response.json();
      const preparedData = {
        taps: data.available_taps - (+localStorage.getItem(storageTaps) ?? 0),
        boosters: data.available_boosters,
        usedBoosters: data.used_boosters,
        paidBoosters: data.paid_boosters,
      };
      yield put(setUserTaps(preparedData));
    } catch (e) {
      console.error(e);
      yield put({ type: cancelTapsLoading.type });
    }
  }
}

export function* getComboInfoSaga() {
  const { token, comboData } = yield select((state) => state.user);
  if (token) {
    try {
      const response = yield call(fetch, `${process.env.REACT_APP_SERVICE_URL}/api/check-combo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });

      const data = yield response.json();
      yield put(setComboData(data));
      if(comboData?.progress === comboData.total - 1 && data.progress === data.total) {
        yield put(getUserBalanceWithItems())
      }
    } catch (e) {
      console.error(e);
      yield put({ type: cancelComboLoading.type });
    }
  }
}

export function* activateBoostSaga({type}) {
  const token = yield select((state) => state.user.token);
  if (token) {
    try {
      const response = yield call(fetch, `${process.env.REACT_APP_SERVICE_URL}/api/activate-boosters?paid=${type === activateSuperBoost.type}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });

      const data = yield response.json();
      if (data.success && type === activateBoost.type) {
        yield put(setUserBoosters());
      }

    } catch (e) {
      console.error(e);
      yield put({ type: cancelTapsLoading.type });
    }
  }
}

export function* sendUserTapsSaga() {
  while (true) {
    const token = yield select((state) => state.user.token);
    const taps = localStorage.taps ? Number(localStorage.taps) : null;
    const clickTime = localStorage.tapTime ? Number(localStorage.tapTime) : null;
    const currentTime = Math.floor(Date.now() / 1000);
    if (taps && taps >= 20 && token && clickTime && currentTime - clickTime >= 2) {
      const tapsToSend = taps - (taps % 20);
      localStorage.setItem(storageTaps, (taps % 20).toString());
      try {
        yield call(fetch, `${process.env.REACT_APP_SERVICE_URL}/api/mining-complete`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
          },
          body: JSON.stringify({ taps: tapsToSend }),
        });
      } catch (e) {
        console.error(e);
      }
    }
    yield delay(1000);
  }
}

export function* getDailyRewardInfoSaga() {
  const token = yield select((state) => state.user.token);
  if (token) {
    try {
      const response = yield call(fetch, `${process.env.REACT_APP_SERVICE_URL}/api/daily-checkin`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });
      const data = yield response.json();

      const info = {
        availableAt: data.available_at,
        isAvailable: data.is_available,
        last: data.last,
        next: data.next,
        config: data.config,
      };
      yield put(setDailyRewardInfo(info));
    } catch (e) {
      console.error(e);
      yield put({ type: cancelDailyRewardLoading.type });
    }
  }
}

export function* getMainDataSaga() {
  const token = yield select((state) => state.user.token);
  if (token) {
    try {
      const response = yield call(fetch, `${process.env.REACT_APP_SERVICE_URL}/api/main-page-data`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });
      const data = yield response.json();

      const dailyCheckin = {
        availableAt: data.daily_checkin.available_at,
        isAvailable: data.daily_checkin.is_available,
        last: data.daily_checkin.last,
        next: data.daily_checkin.next,
        config: data.daily_checkin.config,
      };
      const availableTaps = {
        taps: data.available_taps - (+localStorage.getItem(storageTaps) ?? 0),
        boosters: data.available_boosters,
        usedBoosters: data.used_boosters,
        paidBoosters: data.paid_boosters,
        maxTaps: data.total_taps,
      };
      const leaderboardData = {
        data: {[data.level_data.lvl]: data.leaderboard.top},
        userInfo: data.leaderboard.player_rank,
        userEarnedInfo: data.leaderboard.player_rank_earned,
        earnedData: {[data.level_data.lvl]: data.leaderboard.top_earned},
      };

      yield put(setLevelData({
        currentLvl: data.level_data.lvl,
        tap: data.level_data.tap,
        taps: data.level_data.taps,
        upgradePrice: data.level_data.upgrade_price,
      }));
      yield put(setUserLeaderboardData(leaderboardData));
      yield put(setMainData({...data,availableTaps,dailyCheckin}));
    } catch (e) {
      console.error(e);
    }
  }
}

export function* claimDailyRewardSaga({ payload }) {
  const token = yield select((state) => state.user.token);
  if (token) {
    try {
      const response = yield call(fetch, `${process.env.REACT_APP_SERVICE_URL}/api/daily-checkin`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify({ day: payload }),
      });
      const data = yield response.json();
      const info = { availableAt: data.available_at, isAvailable: false, last: payload, next: payload + 1 };
      yield put(setDailyRewardInfo(info));
      yield put(getUserBalanceWithItems());
      yield put(addServiceModal({
        name: ServiceModalName.successModal,
        payload: {
          title: 'Congratulations!',
          text: 'You\'ve claimed your daily reward. Keep coming back daily to continue earning even more!',
        },
      }));
    } catch (e) {
      console.error(e);
      yield put({ type: cancelDailyRewardLoading.type });
    }
  }
}


export function* checkBuySuperBoostSaga({ payload }) {
  const token = yield select((state) => state.user.token);
  const hash = localStorage.getItem(superBoostHash);
  const hashPrice = localStorage.getItem(superBoostHashPrice);

  if (token && hash) {
    try {
      let isCompleted = false;
      let count = 0;
      while (!isCompleted) {
        const superBoostInfoModal = localStorage.getItem(superBoostInfoModalStatus);

        const response = yield call(fetch, `${process.env.REACT_APP_SERVICE_URL}/api/orders/status?order=${hash}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
          },
        });

        const data = yield response.json();
        if (data.status === 'closed') {
          sendGTMEvent(`finish_buying_super_boost_${payload}`, 'button', 'click', 'Finish transaction');
          sendGTMPurchaseEvent(hash, +hashPrice, 'USD');
          localStorage.removeItem(superBoostHash);
          localStorage.removeItem(superBoostHashPrice);
          localStorage.removeItem(superBoostInfoModalStatus);
          if (payload === 'stars') {
            yield put(removeServiceModal(ServiceModalName.paymentMethodModal));
          }
          yield put(getUserTaps());
          isCompleted = true;
        } else if (count > 10 || +superBoostInfoModal > 10) {
          count = 0;
          isCompleted = true;
          sendGTMEvent(`abort_buying_super_boost_${payload}`, 'notification', 'show', 'Abort transaction');
          localStorage.removeItem(superBoostHash);
          localStorage.removeItem(superBoostHashPrice);
          localStorage.removeItem(superBoostInfoModalStatus);
        } else {
          yield delay(payload === 'stars' ? 1000 : 9000);
          count++;
          if (payload === 'ton') {
            localStorage.setItem(superBoostInfoModalStatus, (+superBoostInfoModal + 1).toString());
          }
        }
      }
    } catch (e) {
      console.error(e);
    }
  }
}

export function* userSagaWatcher() {
  yield takeLatest([getUserBalanceWithItems.type, recheckUserBalance.type], getUserBalanceSaga);
  yield takeLatest([getUserStatus.type], checkUserExistSaga);
  yield takeLatest([getComboData.type], getComboInfoSaga);
  yield takeLatest([registerUser.type], registerUserSaga);
  yield takeLatest([sendUserTaps.type, increaseUserBalance.type], sendUserTapsSaga);
  yield takeLatest([saveUserWallet.type], saveUserWalletSaga);
  yield takeLatest([getUserTaps.type], getUserTapsSaga);
  yield takeLatest([getDailyRewardInfo.type], getDailyRewardInfoSaga);
  yield takeLatest([claimDailyReward.type], claimDailyRewardSaga);
  yield takeLatest([activateBoost.type, activateSuperBoost.type], activateBoostSaga);
  yield takeLatest([getMainData.type], getMainDataSaga);
  yield takeLatest([checkBuySuperBoosterStatus.type], checkBuySuperBoostSaga);
}
